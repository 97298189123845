import { useState, useEffect } from "react";
import { useAddress } from "@thirdweb-dev/react";
import { useContract, useOwnedNFTs, useNFTBalance } from "@thirdweb-dev/react";
import * as amplitude from "@amplitude/analytics-browser";

import "./step1.css";
import WizardAPI from "../../wizard-api";
import { OpenseaViewNFTTokenURL } from "../../../../constants/common-constants";
import raffleMintGradientImage from "../../../../assets/raffle-mint-gradient.webp";

function PostLaunchStep1({ artistId, membershipId, stepChange, nftDetails, setUserOwnedNftDetails }) {
  const _HexValues = ["4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E"];
  const [mintingLive, setMintingLive] = useState(false);
  const [mintLiveDateOn, setMintLiveDateOn] = useState({});
  const [mintEndCountdown, setMintEndCountdown] = useState({});
  const [userOwnLaunchNFT, setUserOwnLaunchNFT] = useState(false);
  const [collectors, setCollectors] = useState({});
  const [mintCountBeforeUpdate, setMintCountBeforeUpdate] = useState([]);
  const [initialDataSet, setInitialDataSet] = useState(false);

  const [email, setEmail] = useState("");
  const [removeNotification, setRemoveNotification] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [error, setError] = useState(false);
  const [isLimitedEditionRaffle, setIsLimitedEditionRaffle] = useState(false);
  const [allowlist, setAllowlist] = useState([]);

  const address = useAddress();

  let launchNftTokenId = nftDetails[nftDetails.length - 1].token_id;
  let launchNftContractAddress = nftDetails[nftDetails.length - 1].contract_address;
  let userHasPassNFT = false;
  const [allNftDataSet, setAllNftDataSet] = useState(false);

  let userOwnedNftDetails = [];

  let userOwnedNftDataLoading;
  let userOwnedNftData;
  let userOwnedNftDataOwnedError;

  let nftOwnedData = new Array(nftDetails.length);

  for (let i = 0; i < nftDetails.length; i++) {
    let mint_start_date = new Date(`${nftDetails[i].mint_start}Z`);
    let now = new Date();

    // if current drop is auction or the mint is not stated yet then skip that contract
    if (nftDetails[i].mint_url || mint_start_date > now) {
      if (nftDetails[i].mint_url && mint_start_date < now) {
        // console.log("auction contract addres>>>>", i, nftDetails[i]);
        nftOwnedData[i] = FindAuctionWinner(nftDetails[i].contract_address, address, nftDetails[i].token_id);
      }
      continue;
    }
    nftOwnedData[i] = FindNftOwned(nftDetails[i].contract_address, address);

    if (nftDetails[i].contract_address == launchNftContractAddress) {
      userOwnedNftData = nftOwnedData[i][0];
      userOwnedNftDataLoading = nftOwnedData[i][1];
      userOwnedNftDataOwnedError = nftOwnedData[i][2];
    }
  }

  const onValueChange = (evt) => {
    if (showButtonLoader) return;
    setEmail(evt.target.value);
  };

  const handleFocus = (e) => {
    if (showButtonLoader) return;
    setError(false);
    e.currentTarget.select();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const storeEmailAddress = async () => {
    if (showButtonLoader) return;
    setShowButtonLoader(true);
    if (email === "") {
      setShowButtonLoader(false);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      setShowButtonLoader(false);
      return;
    }
    if (!address) {
      stepChange(2);
    }
    const storeEmailRes = await WizardAPI.storeEmail(artistId, membershipId, address, email);
    if (storeEmailRes["success"] && storeEmailRes["code"] === 200) {
      setShowButtonLoader(false);
      setRemoveNotification(false);
      setNotificationMessage("Done! We'll send you email reminders closer to the date of mint.");
    } else {
      setShowButtonLoader(false);
    }
  };

  const downloadCalenderInvite = () => {
    if (showButtonLoader) return;
    window.location.href = nftDetails[0].calender_invite_url;
    setRemoveNotification(false);
    setNotificationMessage("Reminder downloaded! Import the invite into your calendar app to stay updated");
  };

  const findMintEndCountdownTime = () => {
    var mintEndDate = new Date(nftDetails[0].mint_end);
    mintEndDate = convertToLocalTimestamp(mintEndDate);
    const now = new Date();
    var sec_num = (mintEndDate - now) / 1000;
    var days = Math.floor(sec_num / (3600 * 24));
    var hours = Math.floor((sec_num - days * (3600 * 24)) / 3600);
    var minutes = Math.floor((sec_num - days * (3600 * 24) - hours * 3600) / 60);
    var seconds = Math.floor(sec_num - days * (3600 * 24) - hours * 3600 - minutes * 60);

    if (days < 10) {
      days = "0" + days;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    setMintEndCountdown({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  };

  const convertToLocalTimestamp = (timestamp) => {
    var preLaunchStartTime = timestamp + "Z";
    var datetime = new Date(preLaunchStartTime);
    return datetime;
  };

  const findCurrentPhase = () => {
    const mintStartTime = convertToLocalTimestamp(nftDetails[0].mint_start);
    const mintEndDate = convertToLocalTimestamp(nftDetails[0].mint_end);
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    var now = new Date();
    var currentDateAndTime = now;

    // console.log("mint start time>>", mintStartTime);
    // console.log("mint end time>>", mintEndDate);
    // console.log("current time>>>>>>", currentDateAndTime);
    if (mintStartTime <= currentDateAndTime && currentDateAndTime < mintEndDate) {
      findMintEndCountdownTime();
      setMintingLive(true);
    } else {
      let d = new Date(mintStartTime);
      let time = new Intl.DateTimeFormat("default", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      }).format(d);
      time = time.toUpperCase();

      let month = new Intl.DateTimeFormat("default", {
        month: "long",
      }).format(d);
      month = month.toUpperCase();

      setMintLiveDateOn({
        time: time,
        month: month,
        date: d.getDate(),
        year: d.getFullYear(),
        day: weekday[d.getDay()].toUpperCase(),
      });
      setMintingLive(false);
    }
    setInitialDataSet(true);
  };

  const fetchCollectorsDetails = async () => {
    const collectorsDetailsRes = await WizardAPI.getCollectorDetails(artistId, membershipId, nftDetails[0].drop_number);
    if (collectorsDetailsRes["success"] && collectorsDetailsRes["code"] === 200) {
      const data = collectorsDetailsRes["data"];
      const collectorList = data && data.length ? data[0]["collectors"] : [];
      setCollectors(collectorList);
    }
  };

  const getGradient = () => {
    let color1 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color1 += y;
    }
    let color2 = "#";
    for (let i = 0; i < 6; i++) {
      let x = Math.round(Math.random() * (_HexValues.length - 1));
      let y = _HexValues[x];
      color2 += y;
    }
    // keep light color first
    if (color1 < color2) {
      let t = color1;
      color1 = color2;
      color2 = t;
    }
    let angle = Math.round(Math.random() * 100);
    return "linear-gradient(" + angle + "deg, " + color1 + ", " + color2 + ")";
  };

  const isLimitedEditionRaffleMint = () => {
    if (isLimitedEditionRaffle && allowlist && allowlist.length) {
      return true;
    } else {
      return false;
    }
  };

  // -------------------------------------------------
  // useEffects
  // -------------------------------------------------
  useEffect(() => {
    // console.log("collects updated");
    setTimeout(() => {
      try {
        // console.log("changing class...");
        let el0 = document.getElementById("collector-pfp-0");
        let el5 = document.getElementById("collector-pfp-5");
        el0.className = "launch-step1-collector-pfp launch-step1-collector-pfp-zero-size";
        el5.className = "launch-step1-collector-pfp launch-step1-collector-pfp-full-size";
      } catch (e) {
        //
      }
    }, 1300);
  }, [collectors]);

  useEffect(() => {
    //checking if the user has launch nft or not
    if (userOwnedNftData) {
      if (userOwnedNftData.length === 0) {
        setUserOwnLaunchNFT(false);
      } else {
        setUserOwnLaunchNFT(true);
      }
    }
  }, [userOwnedNftData]);

  useEffect(() => {
    let mintCountAfterUpdate = [];
    let allDataLoaded = true;
    userOwnedNftDetails = [];
    let now = new Date();
    for (let i = 0; i < nftOwnedData.length; i++) {
      if (!nftOwnedData[i]) {
        continue;
      }

      let nftsOwned = nftOwnedData[i][0];
      let nftDataLoading = nftOwnedData[i][1];
      let nftDataError = nftOwnedData[i][2];
      if (nftDataLoading) {
        allDataLoaded = false;
      }

      // auction contract data
      let mint_start_date = new Date(`${nftDetails[i].mint_start}Z`);
      if (nftDetails[i].mint_url) {
        if (!nftDataLoading && !nftDataError && nftsOwned["_hex"] && mint_start_date < now) {
          let nftCount = parseInt(nftsOwned["_hex"], 16);
          if (nftCount > 0) {
            nftDetails[i]["mint_count"] = 1;
          }
        }
        userOwnedNftDetails.push(nftDetails[i]);
        continue;
      }

      if (nftDataLoading === false && !nftDataError) {
        for (let j = 0; j < nftsOwned.length; j++) {
          if (nftsOwned[j]["type"] === "ERC1155") {
            // if the token is ERC1155 then only need the count of the nft user owned
            nftDetails[i]["mint_count"] = Number(nftsOwned[j]["quantityOwned"]);
          } else {
            // if the token is ERC721 then need image and token id
            nftDetails[i]["mint_count"] = j + 1;
            nftDetails[i]["nft_details"][j] = {
              media: nftsOwned[j]["metadata"]["image"],
              mediaType: "image",
              tokenId: nftsOwned[j]["metadata"]["id"],
            };
          }
        }
        userOwnedNftDetails.push(nftDetails[i]);
      }
    }

    // checking if user has launch pass or not
    for (let i = 0; i < userOwnedNftDetails.length; i++) {
      mintCountAfterUpdate.push(userOwnedNftDetails[i]["mint_count"]);
      if (
        userOwnedNftDetails[i]["contract_address"] == launchNftContractAddress &&
        userOwnedNftDetails[i]["mint_count"] > 0
      ) {
        if (!userHasPassNFT) {
          userHasPassNFT = true;
        }
      }
    }

    // if all the nft data is loaded and user has pass then update the data of the owned nfts
    // or if there are some change in the count of the nfts user owned
    if (allDataLoaded) {
      if (
        (!allNftDataSet && userHasPassNFT) ||
        (mintCountBeforeUpdate.length == mintCountAfterUpdate.length &&
          JSON.stringify(mintCountBeforeUpdate) != JSON.stringify(mintCountAfterUpdate) &&
          mintCountBeforeUpdate.length == nftDetails.length &&
          userHasPassNFT)
      ) {
        userOwnedNftDetails.reverse();
        setUserOwnedNftDetails(userOwnedNftDetails, true);
        setAllNftDataSet(true);
        let current_drop_mint_start_date = new Date(`${nftDetails[0].mint_start}Z`);
        if (nftDetails[0].mint_url && current_drop_mint_start_date < now) {
          // if user own pass nft and currently auction is going on then redirect to step3
          stepChange(3);
        }
      }
    }

    // if there are some changes in the mint count then update the data
    if (JSON.stringify(mintCountBeforeUpdate) != JSON.stringify(mintCountAfterUpdate)) {
      setMintCountBeforeUpdate(mintCountAfterUpdate);
    }
  }, [nftOwnedData]);

  useEffect(() => {
    // checking if it is the limited edition raffle and setting the allowlist
    if (
      nftDetails[0] &&
      nftDetails[0]["nft_mint_transaction"] &&
      nftDetails[0]["nft_mint_transaction"].length &&
      nftDetails[0]["nft_mint_transaction"][0] == "limited_edition_raffle"
    ) {
      setIsLimitedEditionRaffle(true);
      if (nftDetails[0].allowlist && nftDetails[0].allowlist.length) {
        setAllowlist(nftDetails[0].allowlist);
      }
    }
    findCurrentPhase();
    findMintEndCountdownTime();
    fetchCollectorsDetails();
    // console.log("post-launch-step1.js>>>>>>", nftDetails);
  }, []);

  // prefill the email if email of user is already present
  useEffect(() => {
    if (address) {
      WizardAPI.getMemberDetails(artistId, membershipId, address, "").then((response) => {
        if (response && response.data && response.code == 200) {
          if (response.data.email) {
            setEmail(response.data.email);
          }
        }
      });
    } else {
      setEmail("");
    }
    // Trigger Amplitude Event
    try {
      amplitude.track("Home Page Viewed", {
        wallet_connected: address ? "True" : "False",
        minting: "Perk1",
        artist_id: artistId,
        membership_id: membershipId,
      });
    } catch (e) {}
  }, [address]);

  return (
    <>
      <div
        className="post-launch-step1-container"
        style={{
          backgroundImage:
            address && mintingLive && isLimitedEditionRaffleMint() && allowlist.includes(address.toLowerCase())
              ? `url(${raffleMintGradientImage})`
              : "",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {removeNotification ? (
          ""
        ) : (
          <>
            {/* title and desc of the nft */}
            <div className="post-launch-step1-notification-container">
              <div className="post-launch-step1-notification-text-container">{notificationMessage}</div>
              <img
                className="post-launch-step1-notification-remove-image"
                src={require("../../../../assets/remove.webp")}
                onClick={() => setRemoveNotification(true)}
                alt="cross"
              />
            </div>
          </>
        )}
        {(nftDetails[0].mint_url && address && userOwnLaunchNFT && mintingLive) ||
        (address && userOwnedNftDataLoading) ? (
          <div className="post-launch-auction-loading-container">
            <img className="step-loader-image" src={require("../../../../assets/step-loader.png")} alt="loader" />
          </div>
        ) : (
          ""
        )}
        <div className="post-launch-step1-nft-details-container">
          {nftDetails[0].mint_url && mintingLive ? (
            !address ? (
              <div className="post-launch-step1-nft-name-desc-container">
                <div className="post-launch-step1-nft-name-container">
                  <div className="post-launch-step1-nft-name-text">{nftDetails[0].title}</div>
                </div>
                <div className="post-launch-step1-nft-desc-container">
                  <div className="post-launch-step1-nft-desc-text">{nftDetails[0].description}</div>
                </div>
              </div>
            ) : allNftDataSet && !userOwnLaunchNFT ? (
              <div className="post-launch-step1-nft-name-desc-container">
                <div className="post-launch-step1-nft-name-container">
                  <div className="post-launch-step1-nft-name-text">{nftDetails[0].title}</div>
                </div>
                <div className="post-launch-step1-nft-desc-container">
                  <div className="post-launch-step1-nft-desc-text">{nftDetails[0].description}</div>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="post-launch-step1-nft-name-desc-container">
              <div className="post-launch-step1-nft-name-container">
                <div className="post-launch-step1-nft-name-text">{nftDetails[0].title}</div>
              </div>
              <div className="post-launch-step1-nft-desc-container">
                <div className="post-launch-step1-nft-desc-text">{nftDetails[0].description}</div>
              </div>
            </div>
          )}

          {/* if the minting is live or not */}
          <div className="post-launch-step1-nft-date-container">
            {/* raffle-mint winning notification */}
            {initialDataSet &&
            address &&
            mintingLive &&
            isLimitedEditionRaffleMint() &&
            allowlist.includes(address.toLowerCase()) ? (
              <div className="post-launch-step1-raffle-notification-container">
                <div className="post-launch-step1-raffle-notification-image-container">
                  <img
                    className="post-launch-step1-raffle-notification-image"
                    src={require("../../../../assets/raffle-notification-design.webp")}
                    alt="raffle-mint-design"
                  />
                </div>
                <div className="post-launch-step1-raffle-notification-text ">
                  Congratulations! You've won a spot to mint this special edition.
                </div>
              </div>
            ) : (
              ""
            )}

            {initialDataSet ? (
              mintingLive ? (
                nftDetails[0].mint_url ? (
                  ""
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="post-launch-step1-mint-live-heading-text-container">
                      <div className="post-launch-step1-mint-live-heading-text">
                        {nftDetails[0].mint_url ? "Auction Live!" : "Mint available!"}
                      </div>
                    </div>
                    <div className="post-launch-step1-mint-live-countdown-container">
                      {address &&
                      mintingLive &&
                      isLimitedEditionRaffleMint() &&
                      allowlist.includes(address.toLowerCase()) ? (
                        ""
                      ) : (
                        <div className="post-launch-step1-mint-live-end-text">Ends in</div>
                      )}
                      <div className="post-launch-step1-mint-live-day-container">
                        <div className="post-launch-step1-mint-live-value">{mintEndCountdown.days}</div>
                        <div className="post-launch-step1-mint-live-text">days</div>
                      </div>
                      <div className="post-launch-step1-mint-live-hour-container">
                        <div className="post-launch-step1-mint-live-value">{mintEndCountdown.hours}</div>
                        <div className="post-launch-step1-mint-live-text">hours</div>
                      </div>
                      <div className="post-launch-step1-mint-live-minute-container">
                        <div className="post-launch-step1-mint-live-value">{mintEndCountdown.minutes}</div>
                        <div className="post-launch-step1-mint-live-text">minutes</div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="post-launch-step1-not-live-mint-text-container">
                    <div className="post-launch-step1-not-live-mint-text">Coming up next</div>
                  </div>
                  <div className="post-launch-step1-not-live-mint-date-container">
                    <div className="post-launch-step1-not-live-day">
                      <div className="post-launch-step1-not-live-day-text">{mintLiveDateOn.day}</div>
                    </div>
                    <div className="post-launch-step1-not-live-mint-date">
                      <div className="post-launch-step1-not-live-mint-date-text">{mintLiveDateOn.date}</div>
                    </div>
                    <div className="post-launch-step1-not-live-month">
                      <div className="post-launch-step1-not-live-month-text">{mintLiveDateOn.month}</div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>

        {/* wallet is connected but nft balance of user is loading */}
        <div className="post-launch-step1-navigation-container">
          {address && userOwnedNftDataLoading ? (
            <div className="loading-container">
              <img className="step-loader-image" src={require("../../../../assets/step-loader.png")} alt="loader" />
            </div>
          ) : (
            ""
          )}

          {/* user does'nt own launch nft */}
          {address && !userOwnedNftDataLoading && userOwnedNftData.length === 0 && !userOwnLaunchNFT ? (
            <div className="post-launch-step1-launch-nft-not-found-container">
              <div className="post-launch-step1-launch-nft-not-found-text-container">
                <div className="post-launch-step1-launch-nft-not-found-text">You don't have access to this</div>
              </div>
              <div className="post-launch-step1-button-container">
                <div
                  className="post-launch-step1-button"
                  onClick={() => {
                    // __CONFIG__
                    let url = `${OpenseaViewNFTTokenURL}/${nftDetails[0].contract_address}`;
                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  <div className="post-launch-step1-button-text">Buy on secondary</div>
                </div>
              </div>
              <div className="post-launch-step1-thirdweb-container">
                <img
                  className="post-launch-step1-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {/* wallet connected, user has launch nft and minting is live */}
          {address && !userOwnedNftDataLoading && userOwnLaunchNFT && mintingLive ? (
            <>
              {/* raffle-mint not allowed message */}
              {isLimitedEditionRaffleMint() && !allowlist.includes(address.toLowerCase()) ? (
                <div className="post-launch-step1-raffle-mint-message-container">
                  <div className="post-launch-step1-raffle-mint-message">
                    Sorry! You don't have access to mint this edition.
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="post-launch-step1-button-container">
                <div
                  className="post-launch-step1-button"
                  onClick={() => {
                    // if raffle-mint is live and user doesn't have access then disable the mint button
                    if (isLimitedEditionRaffleMint() && !allowlist.includes(address.toLowerCase())) {
                    } else {
                      stepChange(3);
                    }
                    // Trigger Amplitude Event
                    try {
                      amplitude.track("Home Page Button Clicked", {
                        wallet_connected: address ? "True" : "False",
                        minting: "Perk1",
                        artist_id: artistId,
                        membership_id: membershipId,
                        button: "mint_primary_cta",
                      });
                    } catch (e) {}
                  }}
                  style={{
                    background:
                      isLimitedEditionRaffleMint() && !allowlist.includes(address.toLowerCase())
                        ? "rgba(0, 0, 0, 0.5)"
                        : "",
                  }}
                >
                  <div
                    className="post-launch-step1-button-text"
                    style={{
                      padding: "30px",
                      color:
                        isLimitedEditionRaffleMint() && !allowlist.includes(address.toLowerCase()) ? "#C4C4C4" : "",
                    }}
                  >
                    {nftDetails[0].mint_url ? "Bid now" : "Mint now"}
                  </div>
                </div>
              </div>
              {!nftDetails[0].mint_url ? (
                <div className="post-launch-step1-thirdweb-container">
                  <img
                    className="post-launch-step1-thirdweb-logo"
                    src={require("../../../../assets/thirdweb-logo.webp")}
                    alt="thirdweb-logo"
                  />
                </div>
              ) : (
                ""
              )}
              {/* ---------------------------------------------------------- */}
              {/* Collected By Element */}
              {/* ---------------------------------------------------------- */}
              {nftDetails[0].mint_url ? (
                ""
              ) : (
                <div className="launch-step1-collector-container">
                  {collectors.length > 0 ? (
                    <>
                      <div className="launch-step1-collector-text-container">Collected by</div>
                      <div className="launch-step1-collector-details-container">
                        {collectors.map((address, index) => {
                          if (index <= 5) {
                            return (
                              <div
                                key={index}
                                id={`collector-pfp-${index}`}
                                className="launch-step1-collector-pfp"
                                style={{ background: getGradient() }}
                                onClick={() => {
                                  window.open(`${OpenseaViewNFTTokenURL}/${nftDetails[0].contract_address}`, "_blank");
                                }}
                              ></div>
                            );
                          } else {
                            if (index === 6) {
                              return (
                                <div
                                  key={index}
                                  className="launch-step1-collector-size"
                                  onClick={() => {
                                    window.open(
                                      `${OpenseaViewNFTTokenURL}/${nftDetails[0].contract_address}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  +{collectors.length - 5 <= 9 ? "0" : ""}
                                  {collectors.length - 5}
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {/* wallet connected, user has launch nft and minting is not live  */}
          {address && !userOwnedNftDataLoading && userOwnLaunchNFT && !mintingLive ? (
            <div className="post-launch-step1-has-launch-nft-minting-not-live-container">
              <div className="post-launch-step1-has-launch-nft-minting-not-live-error-container">
                {error ? "Please enter a valid email address" : ""}
              </div>
              <input
                className="input-box"
                defaultValue={email}
                placeholder="Enter email address"
                onInputCapture={onValueChange}
                onFocus={handleFocus}
                disabled={showButtonLoader}
                autoFocus
              />

              <div className="post-launch-step1-main-button-container">
                <div className="post-launch-step1-main-button" onClick={storeEmailAddress}>
                  <div className="post-launch-step1-main-button-text">
                    {showButtonLoader ? (
                      <img
                        className="post-launch-step1-button-loader"
                        src={require("../../../../assets/button-loader.gif")}
                        alt="button-loader"
                      />
                    ) : (
                      "Subscribe for reminders"
                    )}
                  </div>
                </div>
              </div>
              <div className="pre-launch-step4-decision-container">or</div>
              <div className="pre-launch-step4-secondary-button-container">
                <div className="pre-launch-step4-secondary-button-text" onClick={downloadCalenderInvite}>
                  Add to calendar
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* wallet is not connected */}
          {!address ? (
            <>
              <div className="post-launch-step1-button-container">
                <div
                  className="post-launch-step1-button"
                  onClick={() => {
                    stepChange(2);
                    // Trigger Amplitude Event
                    try {
                      amplitude.track("Home Page Button Clicked", {
                        wallet_connected: address ? "True" : "False",
                        minting: "Perk1",
                        artist_id: artistId,
                        membership_id: membershipId,
                        button: "mint_primary_cta",
                      });
                    } catch (e) {}
                  }}
                >
                  <div className="post-launch-step1-button-text">Connect Wallet</div>
                </div>
              </div>
              <div className="post-launch-step1-thirdweb-container">
                <img
                  className="post-launch-step1-thirdweb-logo"
                  src={require("../../../../assets/thirdweb-logo.webp")}
                  alt="thirdweb-logo"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export const FindNftOwned = (contractAddress, address) => {
  const nftContractDetails = useContract(contractAddress);

  let contract = nftContractDetails.contract;
  let nftContractDetailsLoading = nftContractDetails.isLoading;
  let nftContractDetailsError = nftContractDetails.error;
  // console.log("contract details>>>>", contractAddress, contract);
  // console.log("error contact>>>>", contractAddress, nftContractDetailsError);
  let nftOwnedRes = useOwnedNFTs(contract, address);
  let nftOwnedData = nftOwnedRes["data"];
  let nftOwnedResLoading = nftOwnedRes["isLoading"];
  let nftOwnedResError = nftOwnedRes["error"];
  // console.log("from the function>>>>>>>>>>", contractAddress, nftOwnedData);
  // console.log("from the function loading", contractAddress, nftOwnedResLoading);
  // console.log("function error>>>>", contractAddress, nftOwnedResError);
  return [nftOwnedData, nftOwnedResLoading, nftOwnedResError];
};

export const FindAuctionWinner = (contractAddress, address, tokenId) => {
  const nftContractDetails = useContract(contractAddress);

  let contract = nftContractDetails.contract;
  let nftContractDetailsLoading = nftContractDetails.isLoading;
  let nftContractDetailsError = nftContractDetails.error;
  // console.log("contract details>>>>", contractAddress, contract);
  // console.log("error contact>>>>", contractAddress, nftContractDetailsError);
  const nftOwnedRes = useNFTBalance(contract, address, tokenId);
  let nftOwnedData = nftOwnedRes["data"];
  let nftOwnedResLoading = nftOwnedRes["isLoading"];
  let nftOwnedResError = nftOwnedRes["error"];
  // console.log("from the function>>>>>>>>>>", contractAddress, nftOwnedData);
  // console.log("from the function loading", contractAddress, nftOwnedResLoading);
  // console.log("function error>>>>", contractAddress, nftOwnedResError);
  return [nftOwnedData, nftOwnedResLoading, nftOwnedResError];
};

export default PostLaunchStep1;
